import React from "react";
import { useState, useEffect } from "react";
import { fetchData } from "../api";
import ErrorModal from "../components/ErrorModal";
import { ring2 } from "ldrs";

const Cardresultcomponent = () => {
  const [statsData, setStatsData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };
  const test = async () => {
    try {
      const res = await fetchData(
        `${process.env.REACT_APP_GNOSIS_TRANSACTIONS}`
      );
      // const response = await res.json();
      setStatsData(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError("Something went wrong. Please try later.");
      console.error("Error caught in loadData:", error.message);
    }
  };

  useEffect(() => {
    test();
  }, []);
  ring2.register();
  return (
    <div className="mx-2 mt-2">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <span>
            <l-ring-2
              size="40"
              stroke="5"
              stroke-length="0.25"
              bg-opacity="0.1"
              speed="0.8"
              color="white"
            ></l-ring-2>
          </span>
        </div>
      ) : (
        <div>
          {!statsData ? (
            <div className="text-white flex items-center justify-center my-4 text-[20px]">
              Data Not Found
            </div>
          ) : (
            <div className="overflow-x-auto mt-8 bg-[#0F2434] rounded-3xl mx-[10px] sm:mx-auto lg:w-[60rem] xl:w-[85rem]">
              <table className="w-full table-auto bg-[#0F2434] rounded-3xl">
                <thead>
                  <tr className="bg-[#0F2434]">
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      Index
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      Gas Limit
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      Gas Used
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      Priority Fee
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      Confirmations
                    </th>
                    <th className="px-6 py-3 text-left text-[10px] sm:text-[12px] md:text-[14px] font-semibold text-white uppercase tracking-wider">
                      TX Count
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                      TX Fee
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#0F2434]  ">
                  {statsData?.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-[#071120] border-b-[1px] border-[#0F2434] w-[30rem]"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {index}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.gas_limit || 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.gas_used || 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.priority_fee || 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.confirmations || 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.block || 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.fee?.value || 0}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-[9px] sm:text-sm text-white">
                        {item?.tx_types?.slice(0, 13) || 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      <ErrorModal
        show={showModal}
        title="Error"
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default Cardresultcomponent;
