import React, { useState, useEffect } from "react";
import Truncate from "react-truncate";

export const menuItem = [
  {
    id: "1",
    name: "Connecting MetaMask to IDEA 3.0",
    path: "#Connecting MetaMask to IDEA 3.0",
  },
  {
    id: "2",
    name: "Installing and Setting Up MetaMask",
    path: "#Installing and Setting up MetaMask",
  },
  // {
  //   id: "3",
  //   name: "Step 1: Download MetaMask",
  //   path: "#Step 1: Download MetaMask",
  // },
  // {
  //   id: "4",
  //   name: "Step 2: Create a New Wallet",
  //   path: "#Step 2: Create a New Wallet",
  // },
  // {
  //   id: "5",
  //   name: "Step 3: Secure Your Wallet",
  //   path: "#Step 3: Secure Your Wallet",
  // },
  // {
  //   id: "6",
  //   name: "Step 4: Write Down Your Secret Recovery Phrase",
  //   path: "#Step 4: Write Down Your Secret Recovery Phrase",
  // },
  // {
  //   id: "7",
  //   name: "Step 5: Confirm Your Secret Recovery Phrase",
  //   path: "#Step 5: Confirm Your Secret Recovery Phrase",
  // },
  {
    id: "8",
    name: "Adding the IDEA Network to Your MetaMask",
    path: "#Adding the IDEA Network to Your MetaMask",
  },
  // {
  //   id: "9",
  //   name: "Step 1: Access the Networks Menu",
  //   path: "#Step 1: Access the Networks Menu",
  // },
  // {
  //   id: "10",
  //   name: "Step 2: Fill in the IDEA Network Details",
  //   path: "#Step 2: Fill in the IDEA Network Details",
  // },
  // {
  //   id: "11",
  //   name: "Step 3: Save the Network",
  //   path: "#Step 3: Save the Network",
  // },
  // {
  //   id: "12",
  //   name: "Step 4: Confirm Connection to the IDEA Network",
  //   path: "#Step 4: Confirm Connection to the IDEA Network",
  // },
  // {
  //   id: "13",
  //   name: "Step 5: Fund Your Wallet",
  //   path: "#Step 5: Fund Your Wallet",
  // },
  {
    id: "14",
    name: "Making Transactions on the IDEA Network",
    path: "#Making Transactions on the IDEA Network",
  },
  // {
  //   id: "15",
  //   name: "Step 1: Copy Your Wallet Address",
  //   path: "#Step 1: Copy Your Wallet Address",
  // },
  // {
  //   id: "16",
  //   name: "Step 2: Use the IDEA Network Faucet",
  //   path: "#Step 2: Use the IDEA Network Faucet",
  // },
  // {
  //   id: "17",
  //   name: "Step 3: Wait for the Funds to Arrive",
  //   path: "#Step 3: Wait for the Funds to Arrive",
  // },
  // {
  //   id: "18",
  //   name: "Step 4: Make a Transaction",
  //   path: "#Step 4: Make a Transaction",
  // },
  // {
  //   id: "19",
  //   name: "Step 5: Confirm the Transaction",
  //   path: "#Step 5: Confirm the Transaction",
  // },
  { id: "19", name: "Closing Thoughts", path: "#Closing Thoughts" },
  //   { id: "19", name: "Step 3: Secure Your Wallet", path: "#step3" },
  //   { id: "19", name: "Step 3: Secure Your Wallet", path: "#step3" },
  //   { id: "19", name: "Step 3: Secure Your Wallet", path: "#step3" },
];

const StepperKno = () => {
  const [activeSection, setActiveSection] = useState("");

  // Detect the active section based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("div[id]");
      let currentSection = "";
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom >= 100) {
          currentSection = section.id;
        }
      });
      setActiveSection(currentSection);
      const activeLink = document.querySelector(`a[href="#${currentSection}"]`);
      if (activeLink) {
        activeLink.scrollIntoView({
          behavior: "smooth",
          block: "nearest", // Ensures minimal scrolling in the sidebar
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="fixed left-0 top-[8%] hidden md:block z-20">
      <div className={"bg-[#040507] w-[200px] lg:w-[300px]"}>
        <div className="overflow-y-auto h-screen sticky pb-16 pt-3">
          {menuItem.map((item) => (
            <div key={item.id} className="flex md:text-[12px] lg::text-[14px]">
              <a
                href={item.path}
                className={`my-3 cursor-pointer ml-7 ${
                  activeSection === item.path.substring(1)
                    ? "text-blue-500"
                    : ""
                }`}
                // onClick={menuIconClick}
                aria-current={
                  activeSection === item.path.substring(1) ? "page" : undefined
                }
              >
                {item.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepperKno;
