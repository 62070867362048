// import { ethers } from "ethers";
import { JsonRpcProvider, Wallet, parseEther, formatEther } from "ethers";

// Function to send native tokens (ETH, BNB, etc.)
// export const sendNativeTokens = async (
//   userAddress,
//   amount,
//   faucetPrivateKey,
//   providerUrl
// ) => {
//   try {
//     // Initialize provider and signer (private key of the faucet wallet)
//     const provider = new ethers.providers.JsonRpcProvider(providerUrl);
//     const wallet = new ethers.Wallet(faucetPrivateKey, provider);

//     // Create a transaction object
//     const tx = {
//       to: userAddress, // Recipient address
//       value: ethers.utils.parseEther(amount), // Amount to send (in Ether or native token)
//       gasLimit: 21000, // Standard gas limit for simple transfers
//       gasPrice: await provider.getGasPrice(), // Current gas price
//     };

//     // Send the transaction
//     const txResponse = await wallet.sendTransaction(tx);
//     await txResponse.wait(); // Wait for the transaction to be mined

//     console.log("Transaction sent:", txResponse);
//     return txResponse;
//   } catch (error) {
//     console.error("Error sending native tokens:", error);
//     throw new Error("Failed to send native tokens.");
//   }
// };

export const sendNativeTokens = async (
  userAddress,
  amount,
  faucetPrivateKey,
  providerUrl
) => {
  try {
    // Initialize provider and signer (private key of the faucet wallet)
    const provider = new JsonRpcProvider(providerUrl);
    const wallet = new Wallet(faucetPrivateKey, provider);

    // Fetch the current fee data (gas price and max priority fee)
    const feeData = await provider.getFeeData();
    const gasPrice = feeData.gasPrice; // Extract gas price

    // Create a transaction object
    const tx = {
      to: userAddress, // Recipient address
      value: parseEther(amount), // Convert amount to Wei
      gasLimit: 21000, // Standard gas limit for simple transfers
      gasPrice: gasPrice, // Current gas price
    };

    // Send the transaction
    const txResponse = await wallet.sendTransaction(tx);
    await txResponse.wait(); // Wait for the transaction to be mined

    console.log("Transaction sent:", txResponse);
    return txResponse;
  } catch (error) {
    console.error("Error sending native tokens:", error);
    throw new Error("Failed to send native tokens.");
  }
};
