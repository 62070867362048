import React from "react";
import idea from "../assests/svgs/idea12.svg";
import twitter from "../assests/pngs/twitter.png";
import { FaMediumM } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-[#212122]  text-[#FFFFFF] font-Poppins mt-6">
      <div className="flex justify-between md:max-w-screen-xl mx-auto py-4 px-8 xl:px-0 flex-col sm:flex-row sm:flex-wrap">
        <div className="">
          <div className="flex items-center justify-between w-[16rem] sm:w-[18rem] mb-4">
            <img src={idea} alt="" className="w-[66px] h-[75px]" />
            <p className="text-[16px] sm:text-[20px] font-bold">
              Powered by IDEA 3.0
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal w-[283px] sm:w-[383px] text-[14px] sm:text-[16px] text-left">
              "Unleash limitless potential. Idea merges Cosmos’s seamless
              interoperability with cutting-edge development power for the next
              evolution in blockchain."
            </p>
            <p className="font-poppins font-normal w-[283px] sm:w-[383px] text-[14px] sm:text-[16px] text-left mt-4">
              © 2024 IDEASCAN. All Rights Reserved.
            </p>
          </div>
        </div>
        <div className="font-poppins pt-4 md:px-4">
          <h1 className="font-bold text-[20px]">About Us</h1>
          <div className="flex flex-col">
            <a href="#" className="font-medium text-[16px] py-4">
              Terms & Conditions
            </a>
            <a href="#" className="font-medium text-[16px]">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="pt-4">
          <div className="flex-flex-col">
            <h1 className="font-bold text-[20px]">Follow Us</h1>
            <p className="font-medium text-[16px] w-[267px] h-[50px] my-4">
              Stay updated with our diverse social presence.
            </p>
          </div>
          <div className="flex justify-between items-center w-[257px]">
            <a
              href="https://discord.gg/HnvpKBtT"
              target="_blank"
              className="w-[46px] h-[46px] bg-black p-1.5 rounded-md"
            >
              <FaDiscord className="w-[32px] h-[32px]" />
              {/* <img
                src={facebook}
                alt="facebook"
                className="w-[48px] h-[49px]"
              /> */}
            </a>
            <a
              href="https://medium.com/@ideascan"
              target="_blank"
              className="w-[46px] h-[46px] bg-black p-1.5 rounded-md"
            >
              {/* <FaMedium className="w-[38px] h-[39px]" />{" "} */}
              <FaMediumM className="w-[32px] h-[32px]" />
              {/* <img
                src={instagram}
                alt="instagram"
                className="w-[48px] h-[49px]"
              /> */}
            </a>
            {/* <a href="#" className="w-[52px] h-[52px] bg-black p-1.5 rounded-md">
              <FaLinkedinIn className="w-[40px] h-[40px]" />{" "}
            </a> */}
            <a href="https://x.com/Ideascanio" target="_blank">
              <img src={twitter} alt="twitter" className="w-[44px] h-[45px]" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
