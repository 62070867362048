import React, { useEffect, useState } from "react";
import Charts1 from "./Charts1";
import { fetchData } from "../api";
import ErrorModal from "./ErrorModal";

const Hero = () => {
  const [chartData, setChartData] = useState();
  const [arr, setArr] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (error) => {
    setErrorMessage(error);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  useEffect(() => {
    const test = async () => {
      try {
        const res = await fetchData(
          `${process.env.REACT_APP_GNOSIS_STATS_GRAPHS}`
        );
        // const req = await res.json();
        setChartData(res.chart_data);
      } catch (error) {
        handleError("Something went wrong. Please try again later");
        console.error("Error fetching data:", error);
      }
    };
    test();
  }, []);

  const [userData, setUserData] = useState({
    labels: [],
    datasets: [
      {
        label: "Users Gained",
        data: [],
        borderWidth: 2,
        textColor: "white",
      },
    ],
  });

  useEffect(() => {
    if (chartData) {
      // chartData.map((item)=> arr.push(item.date?.substring(8)));
      // let test= arr?.slice(0,10);
      // const labels = test;
      // const txCounts = chartData.map((item) => (item.tx_count/1000));
      setUserData((prevUserData) => ({
        ...prevUserData,
        labels: [],
        datasets: [
          {
            ...prevUserData.datasets[0],
            data: ["40", "8", "5"],
            backgroundColor: ["#0C71BC", "#5DAFEB", "#A2D3E4"],
            borderWidth: 0,
          },
        ],
      }));
    }
  }, [chartData]);

  return (
    <div className="">
      <Charts1 userData={userData} />
      <ErrorModal
        show={showModal}
        title="Error"
        message={errorMessage}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
};

export default Hero;
