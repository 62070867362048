import { ring2 } from "ldrs";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchBar from "./SearchBar";
import { TiTick } from "react-icons/ti";
import { MdContentCopy } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";

const Searchhash = () => {
  ring2.register();

  const location = useLocation();
  const { isLoading, result } = location.state || [];
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(isLoading);
  const [show, setShow] = useState(false);

  // Correctly fetch data without causing an infinite loop
  const fetchDataFromAPI = async () => {
    if (result) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_GNOSIS_TRANSACTIONS_SINGLE}/${result}`
        ); // Adjust the URL as necessary
        const fetchedData = await response.json();
        // console.log(fetchedData[0], "this is fetched data in useEffect");
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading after the data is fetched
      }
    }
  };
  console.log(data, "i am testing data");

  const notify = (message) =>
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progressStyle: { background: "#0061f2" },
      progress: undefined,
      theme: "info",
    });

  // Fetch the data on component mount without looping
  useEffect(() => {
    fetchDataFromAPI(); // Fetch data only once on mount
  }, []); // Empty dependency array ensures it runs only once

  const handleClick = () => {
    setShow((previous) => !previous);
  };

  const formatTimestamp = (timestamp) => {
    // Parse the date string
    const date = new Date(timestamp);

    // Define options for formatting
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZone: "UTC",
    };

    // Format date to local string
    const localDate = new Intl.DateTimeFormat("en-US", options).format(date);

    // Get the UTC offset
    const utcOffset = date.getTimezoneOffset() / -60; // Convert minutes to hours
    const offsetString = `(+${String(utcOffset).padStart(2, "0")}:00 UTC)`;

    return `${localDate} ${offsetString}`;
  };

  // function numberWithCommas(x) {
  //   return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  // }

  function numberWithCommas(x) {
    if (x == null || x === "") {
      console.error("Invalid input: The input is undefined, null, or empty.");
      return ""; // Return an empty string or handle as needed
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const copytext = (address) => {
    if (address) {
      navigator.clipboard.writeText(address);
    }
  };
  const handlecopy = (address) => {
    copytext(address);
    notify("Address copied");
  };

  return (
    <div className="">
      {/* <ToastContainer /> */}
      <SearchBar />
      <div>
        {loading ? (
          <div className="flex justify-center items-center mt-20">
            <span>
              <l-ring-2
                size="40"
                stroke="5"
                stroke-length="0.25"
                bg-opacity="0.1"
                speed="0.8"
                color="white"
              ></l-ring-2>
            </span>
          </div>
        ) : (
          <div className="text-white font-Poppins p-4 md:p-6 lg:p-8 bg-[#101112]">
            <div className="max-w-3xl mx-auto">
              <div className="p-6 md:p-8 rounded-lg shadow-md">
                <div className="mb-4 ">
                  <p className="md:text-lg text-sm font-medium pb-2">
                    Transaction Details
                  </p>
                  <div className="flex items-center">
                    <p className="text-[12px] sm:text-sm md:text-[16px] lg:text-[18px] max-w-3xl">
                      {data?.hash?.slice(0, 30)}...{data?.hash?.slice(55, 66)}
                    </p>
                    <span
                      className="pl-2 cursor-pointer text-gray-400"
                      onClick={() => handlecopy(data.hash)}
                    >
                      <MdContentCopy />
                    </span>
                  </div>
                </div>

                <div className="space-y-2 md:space-y-8">
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">Transaction hash </span>
                    <span className="text-white">
                      {data?.hash?.slice(0, 20)}....{data?.hash?.slice(55, 66)}
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white pb-2 sm:pb-0">
                      Status and method
                    </span>
                    <span className="text-white bg-green-custom py-1 px-1 rounded-md flex items-center w-24">
                      <TiTick className="p-0.5 text-sm mx-0.5 bg-white text-black rounded-lg" />{" "}
                      {data?.result}
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">Block</span>
                    <span className="text-white">
                      <span className="text-[#0086FF] font-light pr-2">
                        {numberWithCommas(data?.block) || "Pending"}
                        {"    "}
                      </span>
                      |{"    "}
                      <span className="text-gray-400 font-light pl-2">
                        {" "}
                        {data?.confirmations} Block confirmations
                      </span>
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">Timestamp</span>
                    <span className="text-[#D8ECEC] font-medium">
                      {formatTimestamp(data?.timestamp)}
                    </span>
                  </div>
                  <div className="flex md:justify-between flex-col md:flex-row">
                    <div className="text-white">From</div>
                    <div className="flex items-center md:justify-center">
                      <span className="hover:underline underline-offset-2 cursor-pointer text-[#0086FF] text-[12px] md:text-[14px] lg:text-[16px]">
                        {data?.from?.hash}{" "}
                      </span>
                      <span
                        className="pl-2 cursor-pointer text-gray-400"
                        onClick={() => handlecopy(data?.from?.hash)}
                      >
                        <MdContentCopy />
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">To</span>
                    <span className="flex items-center md:justify-center">
                      <span className="hover:underline underline-offset-2 cursor-pointer text-[#0086FF] text-[12px] md:text-[14px] lg:text-[16px]">
                        {data?.to?.hash || data?.tx_types}{" "}
                      </span>
                      <span
                        className="pl-2 cursor-pointer text-gray-400"
                        onClick={() => handlecopy(data?.to?.hash)}
                      >
                        <MdContentCopy />
                      </span>
                    </span>
                  </div>
                  {data &&
                    Array.isArray(data.token_transfers) &&
                    data.token_transfers.length > 0 && (
                      <div className="flex justify-between lg:flex-row flex-col">
                        <div className="text-[#D8ECEC] pb-2 lg:pb-0">
                          Tokens transferred
                        </div>
                        <div>
                          {
                            Array.isArray(data.token_transfers)
                              ? data.token_transfers.map((res1, index) => (
                                  <div
                                    key={index}
                                    className="flex text-left sm:items-center sm:w-[25rem] md:w-[32rem] justify-between pb-2 lg:pb-0 md:flex-row flex-col"
                                  >
                                    <div className="flex">
                                      <div className="flex items-center text-[12px] md:text-[14px] lg:text-[16px]">
                                        <p className="text-[#0086FF] hover:underline underline-offset-2 cursor-pointer">
                                          {res1.from.hash.slice(0, 4)}....
                                          {res1.from.hash.slice(38, 42)}
                                        </p>
                                        <span
                                          className="pl-2 cursor-pointer text-gray-400"
                                          onClick={() =>
                                            handlecopy(data.from.hash)
                                          }
                                        >
                                          <MdContentCopy />
                                        </span>
                                      </div>
                                      <FaArrowRight className="mx-2" />
                                      <div className="flex items-center text-[12px] md:text-[14px] lg:text-[16px]">
                                        <p className="text-[#0086FF] hover:underline underline-offset-2 cursor-pointer">
                                          {res1.to.hash.slice(0, 4)}....
                                          {res1.to.hash.slice(38, 42)}
                                        </p>
                                        <span
                                          className="pl-2 cursor-pointer text-gray-400"
                                          onClick={() =>
                                            handlecopy(data.to.hash)
                                          }
                                        >
                                          <MdContentCopy />
                                        </span>
                                      </div>
                                    </div>
                                    {res1.total.value && (
                                      <div className="flex md:flex-row flex-col">
                                        <span className="text-[#D8ECEC] text-[12px] md:text-[14px] lg:text-[16px]">
                                          for
                                        </span>
                                        <span className="text-[#D8ECEC] text-[12px] md:text-[14px] lg:text-[16px] pl-0 md:pl-3">
                                          {(
                                            res1.total.value /
                                            1000000000000000000
                                          ).toFixed(18)}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                ))
                              : null // Fallback if token_transfers is not an array
                          }
                        </div>
                      </div>
                    )}
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">Value</span>
                    <span className="text-[#D8ECEC]">
                      {numberWithCommas(data?.value / 1000000000000000000) +
                        "  IDEA"}
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">Transaction fee</span>
                    <span className="text-[#D8ECEC]">
                      {data?.fee?.value / 1000000000000000000 + "  IDEA"}
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">Gas price</span>
                    <span className="text-[#D8ECEC]">
                      <span className="pr-2">
                        {(data?.gas_price / 1000000000000000000).toFixed(18)}
                      </span>
                      <span className="text-gray-400">
                        ({data?.gas_price / 1000000000} Gwei)
                      </span>
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">Gas usage & limit by txn</span>
                    <span>
                      <span className="text-[#D8ECEC]">
                        {numberWithCommas(data?.gas_used) || 0}
                      </span>{" "}
                      |{" "}
                      <span className="text-[#D8ECEC]">
                        {numberWithCommas(data?.gas_limit)}{" "}
                      </span>
                    </span>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">Gas fees (Gwei)</span>
                    <div>
                      <span className="text-white">
                        Base:{" "}
                        {numberWithCommas(
                          (data?.max_fee_per_gas / 1000000000000000000).toFixed(
                            9
                          )
                        )}
                      </span>
                      {"  "}|
                      <span className="text-white pl-1">
                        Max: {numberWithCommas(data.base_fee_per_gas) || 0}
                      </span>{" "}
                      <span className="text-2xl">|</span>
                      <span className="text-white pl-1">
                        Max priority:{" "}
                        {numberWithCommas(
                          (
                            data?.max_priority_fee_per_gas / 1000000000000000000
                          ).toFixed(9)
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between flex-col md:flex-row">
                    <span className="text-white">Burnt fees</span>
                    <span className="text-white">
                      {numberWithCommas(data?.tx_burnt_fee) || 0} IDEA
                    </span>
                  </div>
                </div>

                <div className="cursor-pointer mt-4">
                  {show ? (
                    <div className="space-y-4">
                      <button className="text-blue-500 " onClick={handleClick}>
                        Hide Details
                      </button>
                      <div className="space-y-2 md:space-y-4">
                        <div className="flex justify-between md:flex-row flex-col">
                          <span className="text-white pb-2 sm:pb-0">Other</span>
                          <div className="flex flex-col w-[24rem] justify-between md:flex-row">
                            <div className="text-white">
                              {" "}
                              <span className="text-white">
                                Txn Type :
                              </span>{" "}
                              <span>{data?.type}</span>{" "}
                            </div>{" "}
                            |
                            <div className="text-white">
                              <span className="text-white">Nonce :</span>{" "}
                              <span>{numberWithCommas(data?.nonce)}</span>
                            </div>{" "}
                            |
                            <div className="text-white">
                              <span className="text-white">Position :</span>{" "}
                              <span>{data?.position || 0}</span>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>Raw input</span>
                          <span
                            className="cursor-pointer text-gray-400"
                            onClick={() => handlecopy(data.raw_input)}
                          >
                            <MdContentCopy />
                          </span>
                        </div>
                        <div className="max-w-3xl h-[15rem] rounded-lg bg-[#1A1B1B] break-all p-3 overflow-y-auto">
                          {data?.raw_input}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <button className="text-blue-500" onClick={handleClick}>
                      View Details
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer toastStyle={{ backgroundColor: "black" }} />
      </div>
    </div>
  );
};

export default Searchhash;
